.logo {
  color: white;
  font-size: 24px;
  text-align: center;
  width: 300px;
}

html,
body,
#root,
.container {
  height: 100%;
}

.select-width {
  width: 120px;
}

.ant-table-tbody{
  background: white;
}
.ant-form-explain{
  text-align: left;
}